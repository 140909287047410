import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Report/Customer', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevlivery(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Report/Delivery', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Report/Log', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadCustomerReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Report/Download/Customer', { params: queryParams })
          .then(response => {
            window.location = response.data.Link
            // const fileURL = window.URL.createObjectURL(response.data.Link)
            // const fileLink = document.createElement('a')

            // fileLink.href = fileURL
            // fileLink.setAttribute('download', 'customer.csv')
            // document.body.appendChild(fileLink)

            // fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    downloadDeliveryReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Report/Download/Delivery', { params: queryParams })
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', 'order.csv')
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    downloadLogReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Report/Download/Log', queryParams)
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileURL
            fileLink.setAttribute('download', 'log.csv')
            document.body.appendChild(fileLink)

            fileLink.click()
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
