<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Start date"
            label-for="helperInput"
          >
            <b-form-datepicker
              id="StartDate"
              v-model="StartDate"
              placeholder="Start Date"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="End Date"
            label-for="helperInput"
          >
            <b-form-datepicker
              id="EndDate"
              v-model="EndDate"
              placeholder="End Date"
              class="mb-1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Order Code Number"
            label-for="helperInput"
          >
            <b-form-input
              id="OrderCodeNumber"
              v-model="OrderCodeNumber"
              placeholder="Order Code Number"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            @click="SearchData()"
          >
            <span class="text-nowrap">Search Order</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BFormGroup, BFormInput,
  BButton,
  BFormDatepicker,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    BFormDatepicker,
    // vSelect,
  },
  props: {
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
    orderCodeNumberFilter: {
      type: [String, null],
      default: null,
    },
    // roleOptions: {
    //   type: Array,
    //   required: true,
    // },
    // planOptions: {
    //   type: Array,
    //   required: true,
    // },
    // statusOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      StartDate: '',
      EndDate: '',
      OrderCodeNumber: '',
    }
  },
  methods: {
    SearchData() {
      this.$emit('update:startDateFilter', this.StartDate)
      this.$emit('update:endDateFilter', this.EndDate)
      this.$emit('update:orderCodeNumberFilter', this.OrderCodeNumber)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
